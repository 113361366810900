import React from 'react'
import { 
  Snackbar,
  SnackbarContent,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import styles from './styles.js'
class Snacks extends React.Component {
  state = {
    open: false,
    message: '',
    type: 'default'
  };
  classes = this.props.classes
  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: '',
      type: 'default'
    })
  };

  openSnackbar = ({ message, type = 'default' }) => {
    this.setState({ open: true, message, type })
  };

  render() {
    const { open } = this.state
    const message = <Typography id='snackbar-message' variant='body1'>{this.state.message}</Typography>
    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        message={message}
        autoHideDuration={100000}
        onClose={this.handleSnackbarClose}
        open={open}
      >
        <SnackbarContent
          className={this.classes[this.state.type]}
          message={message}
          contentprops={{
            'aria-describedby': 'snackbar-message',
          }}
        />
      </Snackbar>
    )
  }
}

Snacks.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(Snacks)
