import React, { Component } from 'react'
import { 
  Typography,
  IconButton,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { Add } from '@material-ui/icons'
import { history } from '../../utils/utils'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import styles from './styles'
import AccountCirclePopup from '../AccountCirclePopup'

class UnauthenticatedComponent extends Component {
  classes = this.props.classes
  render = () => {
    return (
      <div className={this.classes.navRightButtonsContainer}>
        <Typography variant='button' 
          className={this.classes.textColor} >
          <Link to={this.props.link} className={this.classes.navRemoveDefaultLinkStyle}>{this.props.t(this.props.text)}</Link>
        </Typography>
      </div>
    )
  }
}

UnauthenticatedComponent.propTypes = {
  classes: PropTypes.object,
  link: PropTypes.string,
  text: PropTypes.string,
  t: PropTypes.func,
}

export const Unauthenticated = withStyles(styles)(UnauthenticatedComponent)

class AuthenticatedComponent extends Component {
  classes = this.props.classes
  render = () => {
    return (
      <div className={this.classes.navRightButtonsContainer}>
        <IconButton 
          className={this.classes.textColor}
          aria-haspopup='true'
          onClick={() => history.push('/create')}
        >
          <Add />
        </IconButton>
        <AccountCirclePopup logout={this.props.logout} />
      </div>
    )
  }
}

AuthenticatedComponent.propTypes = {
  classes: PropTypes.func,
  logout: PropTypes.func,
}

export const Authenticated = withStyles(styles)(AuthenticatedComponent)
