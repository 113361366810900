import React, { Component, Fragment } from 'react'
import { 
  ListItemText,
  FormControl,
  Typography,
  FormGroup,
  TextField,
  FormLabel,
  Checkbox,
  ListItem,
  Button,
  Card,
  CardActions,
  List,
} from '@material-ui/core'
import { Slider } from 'material-ui-slider'
import PropTypes from 'prop-types'
import { DropzoneArea } from 'material-ui-dropzone'
import superagent from 'superagent'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { apiUrl } from '../../config'
import { history } from '../../utils/utils'
import NavBar from '../../Components/NavBar'
import { Authenticated } from '../../Components/NavBar/rightToolbars'

class Create extends Component {
  state = {
    id: null,
    name: '',
    male: true,
    female: true,
    age_start: 13,
    age_end: 80,
    budget: 5000,
    location: [],
    video: null
  }
  classes = this.props.classes
  componentDidMount = () => {
    if (this.props.location.state) this.setState(this.props.location.state.data)
  }
  onToggle = (value) => {
    return this.setState({ [value]: !this.state[value] })
  }
  onInputChange = ({ target }, type) => {
    this.setState({ [type]: target.value })
  }
  onSubmit = () => {
    const token = window.localStorage.getItem('token')
    const { name, male, female, age_start, age_end, budget, location, video } = this.state
    const data = { name, male, female, age_start, age_end, budget, location }
    const fields = { data: JSON.stringify(data) }
    superagent.post(`${apiUrl}/ad`)
      .set({ Authorization: `Bearer ${token}` })
      .attach('video', video)
      .field(fields)
      .then((() => {
        history.push('/')
        return
      })).catch((err) => {
        console.error(err)
        return
      })
  }
  onDelete = () => {
    superagent.delete(`${apiUrl}/ad/${this.state.id}`)
  }
  onAgeChange = (values) => {
    if (typeof(values[0]) === 'string') {
      return this.setState({ [values[0]]: parseInt(values[1]) })
    }
    this.setState({
      age_start: values[0],
      age_end: values[1],
    })
  }
  onDrop = (video) => {
    this.setState({ video })
  }
  renderName = () => {
    return <FormControl>
      <FormLabel>{this.props.t('name')}</FormLabel>
      <TextField
        defaultValue={this.state.name}
        label={this.props.t('name')}
        type='name'
        name='name'
        autoComplete='name'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'name')}
      />
    </FormControl>
  }
  renderGenderSelection = () => {
    return <Fragment>
      <FormLabel variant='h6'>{this.props.t('gender')}</FormLabel>
      <List>
        <ListItem dense button onClick={() => this.onToggle('male')}>
          <Checkbox
            checked={this.state.male}
            tabIndex={-1}
            disableRipple
          />
          <ListItemText primary={this.props.t('male')} />
        </ListItem>
        <ListItem dense button onClick={() => this.onToggle('female')}>
          <Checkbox
            checked={this.state.female}
            tabIndex={-1}
            disableRipple
          />
          <ListItemText primary={this.props.t('female')} />
        </ListItem>
      </List>
    </Fragment>
  }
  renderAgeSelection = () => {
    return <Fragment>
      <FormLabel variant='h6'>{this.props.t('age')}</FormLabel>
      <div className={this.classes.createAgeLabels}>
        <TextField
          label={this.props.t('age_start')}
          type='text'
          value={this.state.age_start}
          margin='normal'
          variant='outlined'
          onChange={(e) => this.onAgeChange('age_start', e.target.value)}
        />
        <div className={this.classes.createAgeSelectionTo}>
          <Typography align='center'>to</Typography>
        </div>
        <TextField
          label={this.props.t('age_end')}
          type='text'
          value={this.state.age_end}
          margin='normal'
          variant='outlined'
          onChange={(e) => this.onAgeChange('age_end', e.target.value)}
        />
      </div>
      <Slider 
        range
        min={13}
        max={80}
        defaultValue={[this.state.age_start, this.state.age_end]}
        onChange={this.onAgeChange}
      />
    </Fragment>
  }
  renderBudget = () => {
    return <Fragment>
      <FormLabel variant='h6'>{this.props.t('budget')}</FormLabel>
      <TextField
        defaultValue={this.state.budget}
        label={this.props.t('budget')}
        type='text'
        name='budget'
        autoComplete='budget'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'budget')}
      />
    </Fragment>
  }
  renderLocationSelection = () => {
    return <Fragment>
      <FormLabel>{this.props.t('location')}</FormLabel>
      <TextField 
        label={this.props.t('location')}
        type='text'
        name='location'
        autoComplete='location'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'location')}
      />
    </Fragment>
  }
  renderDropZone = () => {
    if (this.props.video) return null // string? Forgot what the prop type were. Need to update asap
    return <DropzoneArea 
      onDrop={this.onDrop}
      filesLimit={1}
      maxFileSize={52428800}
      acceptedFiles={['video/mp4', 'video/webm', 'video/mov']}
      dropzoneText={this.props.t('createVideoDropZone')}
    />
  }
  renderVideo = () => {
    return null
  }
  renderSubmit = () => {
    return <CardActions>
      <Button 
        variant='contained' 
        color='primary'
        onClick={this.onSubmit}
      >{this.props.t('submit')}</Button>
      {this.state.id ? <Button 
        variant='contained' 
        color='secondary'
        onClick={this.onDelete}
      >{this.props.t('delete')}</Button> : null}
    </CardActions>
  }
  render() {
    return <div className={this.classes.create}>
      <NavBar t={this.props.t} >
        <Authenticated logout={this.props.logout} t={this.props.t} />
      </NavBar>
      <Card className={this.classes.createContents}>
        <FormGroup className={this.classes.createForm}>
          {!this.state.id ? <Typography variant='h3'>{this.props.t('createTitle')}</Typography> : null}
          {this.renderName()}
          {this.renderGenderSelection()}
          {this.renderAgeSelection()}
          {this.renderBudget()}
          {this.renderLocationSelection()}
          {this.renderDropZone()}
          {this.renderVideo()}
          {this.renderSubmit()}
        </FormGroup>
      </Card>
    </div>
  }
}

Create.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object,
  video: PropTypes.string,
  classes: PropTypes.object,
  logout: PropTypes.func,
}

export default withStyles(styles)(Create)
