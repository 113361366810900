export default function (theme) {
  const { type } = theme.palette
  return {
    error: {
      backgroundColor: theme.palette.error[type],
    },
    success: {
      backgroundColor: theme.palette.success[type]
    },
  }
}
