import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { Router, Route } from 'react-router-dom'
import superagent from 'superagent'
import { useTranslation } from 'react-i18next'
import { MuiThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'

import './index.css'
import '../node_modules/react-vis/dist/style.css'
import './utils/translation'
import { apiUrl } from './config'
import Home from './Screens/Home'
import Create from './Screens/Create'
import Inspect from './Screens/Inspect'
import Login from './Screens/Login'
import Register from './Screens/Register'
import Settings from './Screens/Settings'
import ResetPassword from './Screens/ResetPassword'
import NotApproved from './Screens/NotApproved'
import Snacks from './Components/Snacks'
import { theme } from './utils/theme'
import { 
  history,
  checkApproval
} from './utils/utils'

const Index = () => {
  const { t, i18n } = useTranslation()
  return <MuiThemeProvider theme={theme('light')}>
    <Routes t={t} changeLanguage={i18n.changeLanguage} />
  </MuiThemeProvider>
}
class Routes extends Component {
  state = {
    authUser: null,
  }
  componentDidMount = async () => {
    await this.load()
    this.forceUpdate()
    this.snackInterval = setInterval(() => {
      if (!this.snacks) return this.forceUpdate()
      clearInterval(this.snackInterval)
    }, 500)
  }
  componentWillUnmount = () => {
    clearInterval(this.snackInterval)
  }
  load = async () => {
    const token = await window.localStorage.getItem('token')
    if (token) {
      return superagent.get(`${apiUrl}/users`)
        .set({ Authorization: `Bearer ${token}` })
        .then(({ body }) => {
          return this.setState({ 
            authUser: {
              id: body.id,
              email: body.email,
              approved: body.approved
            }
          }, () => checkApproval(this.state.authUser))
        }).catch(() => {
          this.logout()
        })
    }
    this.logout()
  }
  onLinkClick = (location) => {
    history.push(location)
  }
  logout = () => {
    window.localStorage.removeItem('token')
    this.setState({ authUser: null })
    if (
      window.location.pathname !== '/login'
      && window.location.pathname !== '/register'
      && window.location.pathname !== '/resetPassword'
      && window.location.pathname.indexOf('/resetpw') < 0
    ) {
      history.push('/login')
    }
  }
  register = debounce((data) => {
    superagent.post(`${apiUrl}/auth/register`)
      .withCredentials()
      .send(data)
      .then(({ body }) => {
        this.setState({ authUser: {
          email: body.email,
          id: body.id
        } })
        window.localStorage.setItem('token', body.token)
        this.snacks.openSnackbar({ message: 'Success!', type: 'success' })
        history.push('/')
        return 
      })
      .catch((err) => {
        console.log(err)
        this.snacks.openSnackbar({ message: this.props.t('registrationError'), type: 'error' })
      })
  }, 1000)

  login = debounce((email, password) => {
    superagent.post(`${apiUrl}/auth/login`)
      .withCredentials()
      .send({ email, password })
      .then(({ body }) => {
        this.setState({ authUser: {
          email: body.email,
          id: body.id
        } })
        window.localStorage.setItem('token', body.token)
        history.push('/')
        return
      })
      .catch((err) => {
        console.log(err.status)
        if (err.status === 401) {
          this.snacks.openSnackbar({ message: 'Invalid email or password', type: 'error' })
          return
        }
        this.snacks.openSnackbar({ message: 'Unable to log in', type: 'error' })
      })
  }, 1000)

  render = () => {
    const standardProps = {
      ...this.props,
      authUser: this.state.authUser,
      logout: this.logout,
      openSnackbar: this.snacks ? this.snacks.openSnackbar : null
    }
    return <Router history={history}>
      <Snacks {...standardProps} ref={(snacks) => this.snacks = snacks} />
      <div>
        <Route path='/' exact render={(props) => {
          return <Home {...props} {...standardProps} load={this.load} />
        }} />
        <Route path='/create' exact render={(props) => {
          return <Create {...props} {...standardProps} />
        }} />
        <Route path='/campaign/:id/edit' exact render={(props) => {
          return <Create {...props} {...standardProps} />
        }} />
        <Route path='/campaign/:id/inspect' exact render={(props) => {
          return <Inspect {...props} {...standardProps} />
        }} />
        <Route path='/login' exact render={(props) => {
          return <Login {...props} {...standardProps} login={this.login} />
        }} />
        <Route path='/register' exact render={(props) => {
          return <Register {...props} {...standardProps} register={this.register} />
        }} />
        <Route path='/settings' exact render={(props) => {
          return <Settings {...props} {...standardProps} />
        }} />
        <Route path='/resetPassword' exact render={(props) => {
          return <ResetPassword {...props} {...standardProps} />
        }} />
        <Route path='/resetpw/:id' exact render={(props) => {
          return <ResetPassword {...props} {...standardProps} /> 
        }} />  
        <Route path='/pending_approval' exact render={(props) => {
          return <NotApproved {...props} {...standardProps} />
        }} />
      </div>
    </Router>
  }
}

Routes.propTypes = {
  t: PropTypes.func
}

ReactDOM.render((<Index />), document.getElementById('root'))
