import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const passwordChecker = (password, confirm) => {
  if (!password) return { status: false, message: 'nullPassword' }
  if (password !== confirm) return { status: false, message: 'passwordDoesNotMatch' }
  if (password.length < 6) return { status: false, message: 'passwordTooShort' }
  return { status: true, message: null }
}

export const checkApproval = ({ approved }) => {
  if (!approved) history.push('/pending_approval')
}
