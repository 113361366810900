import React, { Component } from 'react'
import { 
  Typography,
  Card,
  Button,
  CardContent,
  CardActions
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { history } from '../../utils/utils'

class Create extends Component {
  componentDidMount = () => {
    if (!this.props.authUser) history.replace('/')
  }
  classes = this.props.classes
  render() {
    return <div className={this.classes.approval}>
      <Card className={this.classes.approvalContents}>
        <CardContent>
          <Typography
            variant='h3'
            gutterBottom
          >{this.props.t('pendingApprovalTitle')}</Typography>
          <Typography
            variant='body1'
            gutterBottom
          >{this.props.t('pendingApprovalParagraph')}</Typography>
        </CardContent>
        <CardActions>
          <Button 
            variant='contained' 
            color='primary'
            onClick={() => this.props.logout()}
          >
            {this.props.t('logout')}
          </Button>
        </CardActions>
      </Card>
    </div>
  }
}

Create.propTypes = {
  t: PropTypes.func,
  logout: PropTypes.func,
  classes: PropTypes.object,
  authUser: PropTypes.object
}

export default withStyles(styles)(Create)
