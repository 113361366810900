export default function (theme) {
  return {
    nav: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    appBar: {
      backgroundColor: theme.palette.background.paper
    },
    textColor: {
      color: theme.palette.text.primary
    },
    navRemoveDefaultLinkStyle: {
      textDecoration: 'none',
      color: 'inherit',
    },
    navRightButtonsContainer: {
      display: 'flex',
      alignSelf: 'flex-end',
      marginTop: 'auto',
      marginLeft: 'auto',
      marginBottom: 'auto',
    },
  }
}
