import React, { Fragment } from 'react'
import { AccountCircle } from '@material-ui/icons'
import { Menu, MenuItem, IconButton } from '@material-ui/core'
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import PropTypes from 'prop-types'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { history } from '../../utils/utils'

const useStyles = makeStyles((theme) => createStyles({
  iconColor: {
    color: theme.palette.text.primary,
  },
}))

export default function AccountCirclePopup ({ logout }) {
  const classes = useStyles()
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' })

  const onLogoutClick = () => {
    logout()
    popupState.close()
  }
  
  const onSettingsClick = () => {
    history.push('/settings')
    popupState.close()
  }

  return <Fragment>
    <IconButton
      color='inherit'
      {...bindTrigger(popupState)}
    >
      <AccountCircle className={classes.iconColor} />
    </IconButton>
    <Menu {...bindMenu(popupState)}>
      <MenuItem onClick={onSettingsClick}>Settings</MenuItem>
      <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
    </Menu>
  </Fragment>
}

AccountCirclePopup.propTypes = {
  logout: PropTypes.func
}
