import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { 
  Typography,
  FormLabel,
  TextField,
  Button,
  FormGroup,
  Card
} from '@material-ui/core'
import superagent from 'superagent'
import { withStyles } from '@material-ui/styles'

import styles from './styles.js'
import { apiUrl } from '../../config'
import NavBar from '../../Components/NavBar'
import { Authenticated } from '../../Components/NavBar/rightToolbars'
class Settings extends Component {
  state = {
    email: '',
    new_password: '',
    password: '',
    error: null,
    loading: false,
    success: null
  }
  classes = this.props.classes
  onInputChange = ({ target }, type) => {
    this.setState({ [type]: target.value })
  }
  onSubmit = (type) => {
    const body = {}
    if (type === 'password') {
      body.new_password = this.state.new_password
      body.password = this.state.password
    } else if (type === 'email') {
      body.email = this.state.email
    }
    this.setState({ loading: true })
    const token = window.localStorage.getItem('token')
    superagent.post(`${apiUrl}/users/update`)
      .set({ Authorization: `Bearer ${token}` })
      .withCredentials()
      .send(body)
      .then(() => {
        if (type === 'password') {
          this.props.openSnackbar({ message: this.props.t('passwordChangeSuccess'), type: 'success' })
          return this.props.logout()
        }
        return this.props.openSnackbar({ message: this.props.t('emailChangeSuccess'), type: 'success' })
      })
      .catch((err) => {
        if (err.status === 401) {
          this.props.openSnackbar({ message: this.props.t('loggedOut'), type: 'error' })
          return this.props.logout()
        }
        return this.props.openSnackbar({ message: err.body, type: 'error' })
      })
  }
  renderPasswordChange = () => {
    return <FormGroup className={this.classes.form}>
      <FormLabel>{this.props.t('changePassword')}</FormLabel>
      <TextField 
        label={this.props.t('oldPassword')}
        type='password'
        name='password'
        autoComplete='password'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'password')}
      />
      <TextField 
        label={this.props.t('newPassword')}
        type='password'
        name='password'
        autoComplete='password'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'new_password')}
      />
      <div className={this.classes.formButtonWrapper}>
        <Button
          variant='contained' 
          color='primary'
          onClick={() => this.onSubmit('password')}
        >{this.props.t('submit')}</Button>
      </div>
    </FormGroup>
  }
  renderEmailChange = () => {
    return <FormGroup className={this.classes.form}>
      <FormLabel>{this.props.t('changeEmail')}</FormLabel>
      <TextField
        label={this.props.t('email')}
        type='email'
        name='email'
        autoComplete='email'
        margin='normal'
        variant='outlined'
        onChange={(e) => this.onInputChange(e, 'email')}
      />
      <div className={this.classes.formButtonWrapper}>
        <Button
          variant='contained' 
          color='primary'
          onClick={() => this.onSubmit('email')}
        >{this.props.t('submit')}</Button>
      </div>
    </FormGroup>
  }
  render = () => {
    return <div className={this.classes.settingsContainer}>
      <NavBar t={this.props.t} >
        <Authenticated logout={this.props.logout} t={this.props.t} />
      </NavBar>
      <Card className={this.classes.settings}>
        <Typography variant='h3'>{this.props.t('settings')}</Typography>
        {this.renderEmailChange()}
        {this.renderPasswordChange()}
      </Card>
    </div>

  }
}

Settings.propTypes = {
  classes: PropTypes.object,
  t: PropTypes.func,
  logout: PropTypes.func,
  openSnackbar: PropTypes.func,
}

export default withStyles(styles)(Settings)
