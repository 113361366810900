import React, { Component } from 'react'
import { 
  AppBar, 
  Toolbar, 
  Typography,
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles.js'

class NavBar extends Component {
  classes = this.props.classes
  render = () => {
    return (
      <div className='nav'>
        <AppBar position='fixed' className={this.classes.appBar}>
          <Toolbar>
            <Typography 
              variant='h6'
              color='inherit'
              className={this.classes.textColor}
            >
              <Link 
                to='/'
                className={this.classes.navRemoveDefaultLinkStyle}
              >Streetise Dashboard</Link>
            </Typography>
            {this.props.children}
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

NavBar.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
}

export default withStyles(styles)(NavBar)
