import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
import {
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'

import './index.css'

export default class MapChart extends Component {
  state = { 
    data: null,
    lat: 25.04776,
    lng: 121.53185,
    zoom: 6,
    mapLoaded: false
  }
  map = null
  componentDidMount() {
    mapboxgl.accessToken = 'pk.eyJ1Ijoic2NobmFwcCIsImEiOiJjazQzc3dwaWcwYmZ5M210NnpmbmVyeDFxIn0.LbAkBSuHa_a6BIEA3b01SA'
    this.map = new mapboxgl.Map({
      container: 'mapContainer',
      center: [this.state.lng, this.state.lat],
      zoom: this.state.zoom,
      style: 'mapbox://styles/mapbox/streets-v11',
    })
    this.map.on('load', () => this.setState({ mapLoaded: true }))
  }
  update = (data) => {
    this.setState({ data })
  }
  updateMap = () => {
    if (this.state.data && this.map && this.state.mapLoaded) {
      // return
      this.map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': {
          'type': 'geojson',
          'data': {
            'type': 'Feature',
            'properties': {},
            'geometry': {
              'type': 'LineString',
              'coordinates': [
                [-122.48369693756104, 37.83381888486939],
                [-122.48348236083984, 37.83317489144141],
                [-122.48339653015138, 37.83270036637107],
                [-122.48356819152832, 37.832056363179625],
                [-122.48404026031496, 37.83114119107971],
                [-122.48404026031496, 37.83049717427869],
                [-122.48348236083984, 37.829920943955045],
                [-122.48356819152832, 37.82954808664175],
                [-122.48507022857666, 37.82944639795659],
                [-122.48610019683838, 37.82880236636284],
                [-122.48695850372314, 37.82931081282506],
                [-122.48700141906738, 37.83080223556934],
                [-122.48751640319824, 37.83168351665737],
                [-122.48803138732912, 37.832158048267786],
                [-122.48888969421387, 37.83297152392784],
                [-122.48987674713133, 37.83263257682617],
                [-122.49043464660643, 37.832937629287755],
                [-122.49125003814696, 37.832429207817725],
                [-122.49163627624512, 37.832564787218985],
                [-122.49223709106445, 37.83337825839438],
                [-122.49378204345702, 37.83368330777276]
              ]
            }
          }
        },
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': '#888',
          'line-width': 8
        }
      })
    }
  }
  render = () => {
    this.updateMap()
    return <div className='mapChart'>
      <Typography variant='h4' color='inherit' gutterBottom>
        {this.props.t('viewLocations')}
      </Typography>
      <div id='mapContainer' />
    </div>
  }
}

MapChart.propTypes = {
  t: PropTypes.func,
}
