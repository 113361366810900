export default function (theme) {
  return {
    create: {
      display: 'flex',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      overflow: 'auto',
    },
    createContents: {
      display: 'inline-flex',
      alignItems: 'center',
      flexShrink: '0',
      flexDirection: 'column',
      flexFlow: 'column',
      flexWrap: 'nowrap',
      marginTop: '80px',
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      marginBottom: '80px',
    },
    createForm: { 
      width: '90%',
      paddingTop: '5%',
      paddingBottom: '5%',
    },
    createAgeLabels: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
    createAgeSelectionTo: {
      height: '50%',
      margin: '16px',
    },
  }
}
