import React from 'react'
import {
  XYPlot,
  makeWidthFlexible,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  DiscreteColorLegend,
  Crosshair
} from 'react-vis'
import {
  Typography
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import { 
  convertToBarList,
  getBarSeries
} from '../utils'
const FlexibleXY = makeWidthFlexible(XYPlot)

class AgeChart extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      crosshairValues: []
    }
    this.initBarSeries()
    this.theme = this.props.theme
    this.type = this.props.theme.palette.type
    this.maleColor = this.theme.palette.male[this.type]
    this.femaleColor = this.theme.palette.female[this.type]
    this.colorLegendItems = [{
      title: 'Male',
      color: this.maleColor
    }, {
      title: 'Female',
      color: this.femaleColor
    }]
    this.ageList = null
    this.yDomain = [0, 10]
  }  
  update = (data) => {
    this.ageList = this.processData(data)
    this.normalization()
    this.yDomain = this.getYDomain()
    this.forceUpdate()
  }
  processData = (data) => {
    const age = { male: {}, female: {} }
    data.forEach((item) => {
      const ageBracketBase = Math.floor(item.age / 10)
      const ageBracket = `${ageBracketBase * 10}-${(ageBracketBase + 1) * 10}`
      if (item.gender === 'male') {
        age.male[ageBracket] = age.male[ageBracket] + 1 || 1
        age.female[ageBracket] = age.female[ageBracket] || 0
      } else if (item.gender === 'female') {
        age.female[ageBracket] = age.female[ageBracket] + 1 || 1
        age.male[ageBracket] = age.male[ageBracket] || 0
      }
    })
    return this.createList(age)
  }
  createList = ({ male, female }) => {
    const ageList = {
      male: convertToBarList(male),
      female: convertToBarList(female)
    }
    return ageList
  }
  initBarSeries = () => {
    const ageUpperBound = 85
    const ageLowerBound = 0
    const interval = 10
    this.xTicks = []
    let xToken = null
    for (let idx = ageLowerBound; idx < ageUpperBound; idx+=interval) {
      if (idx + interval < ageUpperBound) {
        xToken = `${idx }-${ idx + interval}`
      } else {
        xToken = `${idx }-${ ageUpperBound}`
      }
      this.xTicks.push(xToken)
    }
    this.xTicks.push(`${ageUpperBound }+`)
  }
  normalization = () => {
    const maleMap = {}
    this.ageList.male.map((item) => maleMap[item.x] = item.y)
    const femaleMap = {}
    this.ageList.female.map((item) => femaleMap[item.x] = item.y)
    this.maleList = this.xTicks.map((x) => {
      return {
        x: x,
        y: maleMap[x]? maleMap[x]: 0
      }
    })
    this.femaleList = this.xTicks.map((x) => {
      return {
        x: x,
        y: femaleMap[x]? femaleMap[x]: 0
      }
    })
  }
  getYDomain = () => {
    const maleMax = this.maleList.reduce((p, v) => p > v.y ? p : v.y)
    const femaleMax = this.femaleList.reduce((p, v) => p > v.y ? p : v.y)
    const max = maleMax > femaleMax ? maleMax : femaleMax
    if (max < 10) return [0, 10]
    return [0, Math.ceil(max / 10) * 10]
  }

  yAxisFormat = (y) => {
    return y <= 0? 0: y
  }
  onMouseLeave = () => {
    this.setState({ crosshairValues: [], })
  };
  onNearestX = (_, { index }) => {
    this.setState({ crosshairValues: [this.maleList[index], this.femaleList[index]], })
  }
  crosshairItemsFormat = () => {
    return [
      { title: this.props.t('male'), value: this.state.crosshairValues[0].y },
      { title: this.props.t('female'), value: this.state.crosshairValues[1].y }
    ]
  }
  render() {
    return <div className='ageChart'>
      <Typography variant='h4' color='inherit' gutterBottom>
        {this.props.t('age')}
      </Typography>
      <FlexibleXY
        xType='ordinal' 
        stackBy='y' 
        height={300} 
        xDistance={100} 
        yDomain={this.yDomain}
        onMouseLeave={this.onMouseLeave}
      >
        <DiscreteColorLegend style={{ position: 'absolute', left: '50px', top: '10px' }}
          orientation='horizontal'
          items={this.colorLegendItems} />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title={this.props.t('age')} tickValues={this.xTicks} />
        <YAxis title={this.props.t('amount')} tickFormat={this.yAxisFormat} />
        {getBarSeries(this.maleList, this.maleColor, { onNearestX: this.onNearestX })}
        {getBarSeries(this.femaleList, this.femaleColor)}
        <Crosshair
          values={this.state.crosshairValues}
          itemsFormat={this.crosshairItemsFormat}
          titleFormat={() => null}
          style={{
            line: {
              background: this.theme.palette.lineStroke[this.type],
            }
          }}
        />
      </FlexibleXY>
    </div>
  }
}

AgeChart.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(AgeChart)
