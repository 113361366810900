import React, { Component } from 'react'
import { 
  Typography,
  Button,
  Card,
  CardActions,
  IconButton
} from '@material-ui/core'
import {
  ToggleButtonGroup,
  ToggleButton
} from '@material-ui/lab'
import { 
  ArrowBack,
  ArrowForward
} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import superagent from 'superagent'
import moment from 'moment'

import styles from './styles'
import { apiUrl } from '../../config'
import NavBar from '../../Components/NavBar'
import { Authenticated } from '../../Components/NavBar/rightToolbars'
import GenderChart from '../../Components/Charts/Gender'
import ViewsChart from '../../Components/Charts/Views'
import AgeChart from '../../Components/Charts/Age'
import MapChart from '../../Components/Charts/Map'
import { getDateFormatUnit } from '../../Components/Charts/utils'
import { history } from '../../utils/utils'


class Inspect extends Component {
  state = {
    data: null,
    range: 'month',
    reference_date: moment.utc().format(),
    genderList: {
      male: [],
      female: [],
      labelData: []
    },
    ageList: {
      male: [],
      female: [],
      labelData: []
    },
  }
  classes = this.props.classes
  componentDidMount = () => {
    this.load()
  }
  load = async () => {
    try {
      const token = window.localStorage.getItem('token')
      const { body } = await superagent.get(`${apiUrl}/views/`)
        .set({ Authorization: `Bearer ${token}` })
        .query({
          ad_id: this.props.location.state.data.id,
          range: this.state.range,
          reference_date: this.state.reference_date
        })
      this.setState({ data: body.data })
      this.updateCharts(body.data)
    } catch (err) {
      console.error(err)
    }
  }
  updateCharts = (data) => {
    this.viewsChart.update(data, this.state.range)
    this.genderChart.update(data, this.state.range)
    this.ageChart.update(data, this.state.range)
    this.mapChart.update(data, this.state.range)
  }
  onHomePress = () => {
    history.push('/')
  }
  onEditPress = () => {
    history.push(`/campaign/${this.props.location.state.data.id}/edit`, this.props.location.state)
  }
  renderActions = () => {
    return <CardActions>
      <Button 
        variant='contained' 
        color='primary'
        onClick={this.onHomePress}
      >{this.props.t('home')}</Button>
      <Button 
        variant='contained' 
        color='primary'
        onClick={this.onEditPress}
      >{this.props.t('edit')}</Button>
    </CardActions>
  }
  onDateChange = (type) => {
    const currMoment = moment.utc(this.state.reference_date)
    let reference_date
    if (type === 'next') {
      reference_date = currMoment.add(1, `${this.state.range }s`).format()
    } else if (type === 'prev') {
      reference_date = currMoment.subtract(1, `${this.state.range }s`).format()
    }
    this.setState({ reference_date }, () => {
      this.load()
    })
  }
  setRange = (_, range) => {
    if (range) {
      this.setState({ range }, () => {
        this.load()
      })
    }
  }
  renderType = () => {
    const format = getDateFormatUnit(this.state.range, 'format')
    return <div className={this.classes.type}>
      <div className={this.classes.ranges}>
        <IconButton onClick={() => this.onDateChange('prev')}>
          <ArrowBack />
        </IconButton>
        <Typography variant='h6'>
          {moment(this.state.reference_date).subtract(1, `${this.state.range}s`).format(format)} - {moment(this.state.reference_date).format(format)} 
        </Typography>
        <IconButton onClick={() => this.onDateChange('next')}>
          <ArrowForward />
        </IconButton>
      </div>
      <ToggleButtonGroup 
        exclusive
        onChange={this.setRange}
        value={this.state.range}>
        <ToggleButton value='day'>Day</ToggleButton>
        <ToggleButton value='month'>Month</ToggleButton>
        <ToggleButton value='year'>Year</ToggleButton>
      </ToggleButtonGroup>
    </div>
  }
  render = () => {
    return <div className={this.classes.inspect}>
      <NavBar t={this.props.t} >
        <Authenticated logout={this.props.logout} t={this.props.t} />
      </NavBar>
      <Card className={this.classes.inspectContents}>
        {this.renderType()}
        <div className={this.classes.inspectContentBody}>
          <Typography variant='h3' gutterBottom>
            {this.props.location.state.data.name}
          </Typography>
          <ViewsChart ref={(chart) => {
            this.viewsChart = chart
          }} {...this.props} />
          <GenderChart ref={(chart) => {
            this.genderChart = chart
          }} {...this.props} />
          <AgeChart ref={(chart) => {
            this.ageChart = chart
          }} {...this.props} />
          <MapChart ref={(chart) => {
            this.mapChart = chart
          }} {...this.props} />
          {this.renderActions()}
        </div>
      </Card>
    </div>
  }
}

Inspect.propTypes = {
  t: PropTypes.func,
  location: PropTypes.object,
  classes: PropTypes.object,
  logout: PropTypes.func,
}

export default withStyles(styles)(Inspect)
