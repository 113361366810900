import React, { Component } from 'react'
import superagent from 'superagent'
import { 
  Card,
  CardMedia,
  Typography,
  CardContent,
  CardActionArea,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import styles from './styles'
import { apiUrl } from '../../config'
import NavBar from '../../Components/NavBar'
import { Authenticated } from '../../Components/NavBar/rightToolbars'
import { httpError } from '../../utils/errors'
import { history } from '../../utils/utils'

class App extends Component {
  state = {
    data: null
  }
  classes = this.props.classes
  componentDidMount = async () => {
    await this.props.load()
    this.getCampaigns()
  }
  getCampaigns = () => {
    const token = window.localStorage.getItem('token')
    superagent
      .get(`${apiUrl}/ad`)
      .set({ Authorization: `Bearer ${token}` })
      .then(({ body }) => {
        return this.setState({ data: body.data })
      })
      .catch((err) => {
        httpError(err, history)
      })
  }
  onCampaignClick = (data) => {
    history.push(`/campaign/${data.id}/inspect`, { data })
  }
  renderVideo = (item) => {
    return <video width='100%' height='100%' controls='controls' type='video/mp4' crossOrigin='*' >
      <source src={item.video} />
    </video>
  }
  renderCampaigns = () => {
    if (!this.state.data) return null
    return this.state.data.map((item) => <Card 
      className={this.classes.campaignItem}
      key={item.id} 
      onClick={() => this.onCampaignClick(item)}  
    >
      <CardActionArea>
        <CardMedia
          component={() => this.renderVideo(item)}
          src={item.video}
        />
        <CardContent>
          <Typography variant='h5' component='h2'>  
            {item.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>)
  }
  render() {
    return <div className={this.classes.homeContainer}>
      <NavBar t={this.props.t} >
        <Authenticated logout={this.props.logout} t={this.props.t} />
      </NavBar>
      <div className={this.classes.home}>
        {this.renderCampaigns()}
      </div>
    </div>
  }
}

App.propTypes = {
  load: PropTypes.func,
  classes: PropTypes.object,
  logout: PropTypes.func,
  t: PropTypes.func,
}

export default withStyles(styles)(App)
