import React from 'react'
import { curveCatmullRom } from 'd3-shape'
import {
  XYPlot,
  makeWidthFlexible,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  LineSeries,
  Crosshair
} from 'react-vis'
import {
  Typography
} from '@material-ui/core'
import { withTheme } from '@material-ui/core/styles'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getDateFormatUnit, getLabelTimeData, getTimeTitle } from '../utils'

const FlexibleXY = makeWidthFlexible(XYPlot)

class ViewsChart extends React.Component {
    state = {
      crosshairValue: []
    }
    theme = this.props.theme
    type = this.props.theme.palette.type
    update = (data, range) => {
      this.range = range
      this.data = data
      this.viewList = this.processData(data)
      this.xTicks = this.viewList.map((item) => item.x)
      this.yDomain = this.getYDomain()
      this.forceUpdate()
    }
    processData = (data) => {
      const time = {}
      data.forEach((item) => {
        const date = moment(item.inserted_at).format(getDateFormatUnit(this.range, 'format', 's'))
        time[date] = (time[date] || 0) + 1
      })
      return this.transFormDataToList(time)
    }
    transFormDataToList = (time) => {
      const amountList = getLabelTimeData(this.range).map((item, idx) => {
        return {
          x: idx,
          xLabel: item.x,
          y: time[item.x] || 0
        }
      })
      return amountList
    }
    xAxisFormat = (x) => {
      return this.viewList[x].xLabel
    }
    yAxisFormat = (y) => {
      return y <= 0? 0: y
    }
    getYDomain = () => {
      const max = this.viewList.reduce((p, v) => p > v.y ? p : v.y)
      if (max < 10) return [0, 10]
      return [0, Math.ceil(max / 10) * 10]
    }
    onMouseLeave = () => {
      this.setState({ crosshairValue: [] })
    };
    onNearestX = (value) => {
      this.setState({ crosshairValue: [value] })
    }
    crosshairItemsFormat = (points) => {
      return [{ title: this.props.t('amount'), value: points[0].y }]
    }
    render() {
      return <div>
        <Typography variant='h4' color='inherit' gutterBottom>
          {this.props.t('views')}
        </Typography>
        <FlexibleXY 
          xType='ordinal' 
          height={300} 
          xDistance={100} 
          yDomain={this.yDomain} 
          onMouseLeave={this.onMouseLeave}
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis 
            title={getTimeTitle(this.props.t, this.range)} tickValues={this.xTicks} 
            tickFormat={this.xAxisFormat} />
          <YAxis 
            title={this.props.t('amount')} 
            tickFormat={this.yAxisFormat}
          />
          <LineSeries
            onNearestX={this.onNearestX}
            style={{
              stroke: this.theme.palette.lineStroke[this.type],
              strokeWidth: 4,
              fill: 'none',
            }}
            curve={
              curveCatmullRom.alpha(0.5)
            }
            data={this.viewList}
          />
          <Crosshair 
            values={this.state.crosshairValue}
            itemsFormat={this.crosshairItemsFormat}
            titleFormat={() => null}
            style={{
              line: {
                background: this.theme.palette.lineStroke[this.type],
              }
            }}
          />
        </FlexibleXY>
      </div>
    }
}

ViewsChart.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(ViewsChart)
