export default function (theme) {
  return {
    inspect: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default
    },
    inspectContents: {
      display: 'inline-flex',
      alignItems: 'center',
      flexShrink: '0',
      flexDirection: 'column',
      flexFlow: 'column',
      flexWrap: 'nowrap',
      marginTop: '80px',
      width: '75%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      marginBottom: '80px',
    },
    inspectContentBody: {
      width: '90%',
      display: 'flex',
      flexDirection: 'column',
      paddingTop: '5%',
      paddingBottom: '5%',
    },
    title: {
      width: '90%',
    },
    bars: {
      width: '100%',
      marginTop: '16px',
    },
    type: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'center',
    },
    ranges: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center,' 
    },
  }
}
