import React from 'react'
import moment from 'moment'
import {
  VerticalBarSeries,
} from 'react-vis'

export const getDateFormatUnit = (range, type, length = 'l') => {
  if (range === 'day') {
    if (type === 'format' && length === 'l') return 'YYYY/MM/DD HH:MM'
    if (type === 'format' && length === 's') return 'HH'
    if (type === 'unit') return 'hours'
  }
  if (range === 'month') {
    if (type === 'format' && length === 'l') return 'YYYY/MM/DD'
    if (type === 'format' && length === 's') return 'DD'
    if (type === 'unit') return 'days'
  }
  if (range === 'year') {
    if (type === 'format' && length === 'l') return 'YYYY/MM'
    if (type === 'format' && length === 's') return 'MM'
    if (type === 'unit') return 'months'
  }
}

export const getLabelTimeData = (range) => {
  const reference_date = moment.utc().format()
  const unit = getDateFormatUnit(range, 'unit')
  const format = getDateFormatUnit(range, 'format', 's')
  const dateMinus1Unit = moment(reference_date).subtract(1, range).add(1, unit).format()
  const timeList = []
  for (
    let t = dateMinus1Unit; 
    moment(t).isBefore(reference_date);
    t = moment(t).add(1, unit)
  ) {
    timeList.push({
      x: moment(t).format(format)
    })
  }
  timeList.push({
    x: moment(reference_date).format(format)
  })
  return timeList
}

export const convertToBarList = (items) => {
  const itemsLists = Object.entries(items)
  return itemsLists.map((item) => ({
    x: item[0],
    y: item[1]
  }))
}
export const getTimeTitle = (t, range) => {
  if (range === 'month') return t('date')
  if (range === 'day') return t('hour')
  if (range === 'year') return t('month')
}

export const getBarSeries = (list, color, props) => {
  return <VerticalBarSeries 
    color={color}
    data={list} 
    {...props}
  />
}
