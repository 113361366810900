export default function (theme) {
  return {
    settingsContainer: {
      display: 'flex',
      minHeight: '100vh',
      minWidth: '100vw',
      alignItems: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
    },
    settings: {
      display: 'inline-flex',
      alignItems: 'center',
      flexShrink: 0,
      flexDirection: 'column',
      flexFlow: 'column',
      flexWrap: 'nowrap',
      marginTop: '80px',
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      marginBottom: '80px',
    },
    form: { 
      width: '90%',
      paddingTop: '5%',
      paddingBottom: '5%',
      display: 'inline-flex',
      flexDirection: 'column',
    },
    formButtonWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    }
  }
}
