import React, { Component } from 'react'
import { 
  Typography,
  TextField, 
  Button,
  Paper
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import styles from './styles'
import NavBar from '../../Components/NavBar'
import { Unauthenticated } from '../../Components/NavBar/rightToolbars'
import { history } from '../../utils/utils'
class Login extends Component {
  state = {
    email: null,
    password: null
  }
  classes = this.props.classes
  onInputChange = ({ target }, type) => {
    this.setState({ [type]: target.value })
  }
  render() {
    return (
      <div className={this.classes.container}>
        <NavBar t={this.props.t} >
          <Unauthenticated link='/register' text='signUp' t={this.props.t} />
        </NavBar>
        <Paper className={this.classes.paper}>
          <Typography variant='h3'>{this.props.t('logIn')}</Typography>
          <form className={this.classes.form} autoComplete='off'>
            <TextField
              label={this.props.t('email')}
              type='email'
              name='email'
              autoComplete='email'
              margin='normal'
              variant='outlined'
              onChange={(e) => this.onInputChange(e, 'email')}
            />
            <TextField
              label={this.props.t('password')}
              type='password'
              name='password'
              autoComplete='current-password'
              margin='normal'
              variant='outlined'
              onChange={(e) => this.onInputChange(e, 'password')}
            />
            <div>
              <Button
                onClick={() => history.push('/resetPassword')}
              >{this.props.t('forgotPassword')}</Button>
              <div className={this.classes.signInSignUp}>
                <Button
                  onClick={() => history.push('/register')}
                >{this.props.t('signUp')}</Button>
                <Button 
                  variant='contained' 
                  color='primary'
                  onClick={() => this.props.login(this.state.email, this.state.password)}
                >
                  {this.props.t('logIn')}
                </Button>
              </div>
            </div>
          </form>
        </Paper>
      </div>
    )
  }
}

Login.propTypes = {
  login: PropTypes.func,
  t: PropTypes.func,
  classes: PropTypes.object
}

export default withStyles(styles)(Login)
