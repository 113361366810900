import { createMuiTheme } from '@material-ui/core/styles'
import { 
  pink, 
  lightBlue, 
  grey,
  green,
  indigo,
  cyan,
  red,
} from '@material-ui/core/colors'

export const theme = (type) => {
  const muiTheme = createMuiTheme({ palette: { type } })
  const themeObj = {
    primary: {
      main: indigo[500]
    },
    secondary: {
      main: cyan[500]
    },
    error: {
      main: red[500]
    },
    success: {
      main: green[500]
    }
  }
  Object.keys(themeObj).forEach((item) => {
    muiTheme.palette[item] = muiTheme.palette.augmentColor(themeObj[item])
  })
  if (type === 'dark') muiTheme.palette.background.default = grey[900]
  const additions = {
    lineStroke: {
      dark: cyan.A200,
      light: cyan.A400
    },
    male: {
      dark: lightBlue.A200,
      light: lightBlue.A400
    },
    female: {
      dark: pink.A200,
      light: pink.A400
    }
  }
  muiTheme.palette = { ...muiTheme.palette, ...additions }
  return muiTheme
}
