import React, { Component } from 'react'
import { 
  Typography,
  TextField, 
  Button,
  Paper
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import superagent from 'superagent'
import debounce from 'lodash.debounce'

import styles from './styles'
import { apiUrl } from '../../config'
import { passwordChecker, history } from '../../utils/utils'
import NavBar from '../../Components/NavBar'
import { Unauthenticated } from '../../Components/NavBar/rightToolbars'

class ResetPassword extends Component {
  state = {
    email: null,
    submitted: false,
    newPassword: null,
    confirmPassword: null
  }
  classes = this.props.classes
  onEmailSubmit = debounce(async () => {
    try {
      await superagent
        .post(`${apiUrl}/auth/resetpw`)
        .send({ email: this.state.email })
      this.setState({
        submitted: true
      })
    } catch (error) {
      this.props.openSnackbar({ message: this.props.t('somethingWentWrong'), type: 'error' })
    }
  }, 1000)
  onPasswordChangeSubmit = debounce(async () => {
    try {
      await superagent
        .post(`${apiUrl}/auth/resetpw_token`)
        .send({ token_id: this.props.match.params.id, password: this.state.newPassword })
      this.props.logout()
    } catch (error) {
      console.log(error)
      this.props.openSnackbar({ message: this.props.t('somethingWentWrong'), type: 'error' })
    }
  }, 1000)
  onInputChange = ({ target }, type) => {
    this.setState({ [type]: target.value })
  }
  renderSubmitted = () => {
    if (!this.state.submitted) return null
    return <Paper className={this.classes.paper}>
      <Typography variant='h3'
        gutterBottom
      >{this.props.t('forgotPassword')}</Typography>
      <Typography variant='body1'>{this.props.t('emailSentParagraph')}</Typography>
    </Paper>
  }
  renderForm = () => {
    if (this.state.submitted) return null
    return <Paper className={this.classes.paper}>
      <Typography variant='h3'>{this.props.t('forgotPassword')}</Typography>
      <form className={this.classes.form} autoComplete='off'>
        <TextField
          label={this.props.t('email')}
          type='email'
          name='email'
          autoComplete='email'
          margin='normal'
          variant='outlined'
          onChange={(e) => this.onInputChange(e, 'email')}
        />
        <div>
          <div className={this.classes.signInSignUp}>
            <Button
              onClick={() => history.goBack()}
            >{this.props.t('cancel')}</Button>
            <Button 
              variant='contained' 
              color='primary'
              onClick={this.onEmailSubmit}
            >{this.props.t('submit')}</Button>
          </div>
        </div>
      </form>
    </Paper>
  }
  renderPasswordReset = () => {
    return <div className={this.classes.container}>
      <Paper className={this.classes.paper}>
        <Typography variant='h3'>{this.props.t('changePassword')}</Typography>
        <form className={this.classes.form} autoComplete='off'>
          <TextField
            label={this.props.t('newPassword')}
            type='password'
            name='password'
            error={!passwordChecker(this.state.newPassword, this.state.confirmPassword).status}
            helperText={this.props.t(passwordChecker(this.state.newPassword, this.state.confirmPassword).message)}
            margin='normal'
            variant='outlined'
            onChange={(e) => this.onInputChange(e, 'newPassword')}
          />
          <TextField
            label={this.props.t('confirmPassword')}
            type='password'
            error={!passwordChecker(this.state.newPassword, this.state.confirmPassword).status}
            helperText={this.props.t(passwordChecker(this.state.confirmPassword, this.state.newPassword).message)}
            name='password'
            margin='normal'
            variant='outlined'
            onChange={(e) => this.onInputChange(e, 'confirmPassword')}
          />
          <div>
            <div className={this.classes.signInSignUp}>
              <Button
                onClick={() => history.goBack()}
              >{this.props.t('cancel')}</Button>
              <Button
                disabled={!passwordChecker(this.state.newPassword, this.state.confirmPassword).status}
                variant='contained' 
                color='primary'
                onClick={this.onPasswordChangeSubmit}
              >{this.props.t('submit')}</Button>
            </div>
          </div>
        </form>
      </Paper>
    </div>
  }
  render() {
    if (this.props.match && this.props.match.params.id) return this.renderPasswordReset()
    return (
      <div className={this.classes.container}>
        <NavBar t={this.props.t} >
          <Unauthenticated link='/login' text='logIn' t={this.props.t} />
        </NavBar>
        {this.renderForm()}
        {this.renderSubmitted()}
      </div>
    )
  }
}

ResetPassword.propTypes = {
  logout: PropTypes.func,
  match: PropTypes.object,
  openSnackbar: PropTypes.func,
  t: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(styles)(ResetPassword)
