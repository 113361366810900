export default function (theme) {
  return {
    homeContainer: {
      width: '100%',
      height: 'calc(100vh - 80px)',
      backgroundColor: theme.palette.background.default,
      paddingTop: '80px',
      overflow: 'scroll'
    },
    home: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    campaignItem: {
      maxWidth: '20%',
      minWidth: '300px',
      margin: theme.spacing(2),
      maxHeight: '300px',
    }
  }
}
