export default function (theme) {
  return {
    approval: {
      display: 'flex',
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      alignContent: 'center',
      flexDirection: 'column',
      backgroundColor: theme.palette.background.default,
      overflow: 'auto',
    },
    approvalContents: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4),
      alignItems: 'center',
      flexShrink: '0',
      flexDirection: 'column',
      flexFlow: 'column',
      minHeight: theme.spacing(45),
      flexWrap: 'nowrap',
      marginTop: '80px',
      width: '50%',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        padding: theme.spacing(2),
      },
      marginBottom: '80px',
    }
  }
}
