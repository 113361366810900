export default function (theme) {
  return {
    container: {
      flex: 1,
      display: 'flex',
      height: '100vh',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      overflow: 'scroll',
      backgroundColor: theme.palette.background.default
    },
    paper: {
      marginTop: '80px',
      overflow: 'scroll',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      width: '50%',
      minHeight: '350px',
      minWidth: '150px',
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      },
      margin: 'auto',
      paddingTop: '16px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: '75%',
      height: '60%',
    },
    signInSignUp: {
      width: '100%',
      marginTop: '2%',
      display: 'flex',
      flex: '1',
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  }
}
