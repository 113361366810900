import React, { Component } from 'react'
import {
  XYPlot,
  makeWidthFlexible,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  Crosshair,
  DiscreteColorLegend
} from 'react-vis'
import {
  Typography
} from '@material-ui/core'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withTheme } from '@material-ui/core/styles'

import { 
  convertToBarList, 
  getDateFormatUnit, 
  getLabelTimeData, 
  getTimeTitle, 
  getBarSeries 
} from '../utils'
const FlexibleXY = makeWidthFlexible(XYPlot)

class GenderChart extends Component {
  constructor(props) {
    super(props)
    this.genderList = null
    this.theme = this.props.theme
    this.type = this.props.theme.palette.type
    this.maleColor = this.theme.palette.male[this.type]
    this.femaleColor = this.theme.palette.female[this.type]
    this.colorLegendItems = [{
      title: 'Male',
      color: this.maleColor
    }, {
      title: 'Female',
      color: this.femaleColor
    }]
    this.yDomain = [0, 10]
    this.state = {
      crosshairValues: [],
    }
  }
  update = (data, range) => {
    this.range = range
    this.genderList = this.processGenderData(data)
    this.normalization()
    this.yDomain = this.getYDomain()
    this.forceUpdate()
  }
  createGenderList = (count) => {
    return {
      male: convertToBarList(count.male),
      female: convertToBarList(count.female),
      labelData: getLabelTimeData(this.range)
    }
  }
  processGenderData = (data) => {
    const count = { male: {}, female: {} }
    data.forEach((item) => {
      const date = moment(item.inserted_at).format(getDateFormatUnit(this.range, 'format', 's'))
      if (item.gender === 'male') {
        count.male[date] = count.male[date] + 1 || 1
        count.female[date] = count.female[date] + 0 || 0
      } else if (item.gender === 'female') {
        count.female[date] = count.female[date] + 1 || 1
        count.male[date] = count.male[date] + 0 || 0
      }
    })
    return this.createGenderList(count)
  }
  normalization = () => {
    const maleMap = {}
    this.genderList.male.forEach((item) => maleMap[item.x] = item.y)
    const femaleMap = {}
    this.genderList.female.forEach((item) => femaleMap[item.x] = item.y)
    this.maleList = this.genderList.labelData.map((label) => {
      return {
        x: label.x,
        y: maleMap[label.x]? maleMap[label.x]: 0
      }
    })
    this.femaleList = this.genderList.labelData.map((label) => {
      return {
        x: label.x,
        y: femaleMap[label.x]? femaleMap[label.x]: 0
      }
    })
  }
  getYDomain = () => {
    const maleMax = this.maleList.reduce((p, v) => p > v.y ? p : v.y)
    const femaleMax = this.femaleList.reduce((p, v) => p > v.y ? p : v.y)
    if (maleMax < 10 && femaleMax < 10) return [0, 10]
    const max = maleMax > femaleMax ? maleMax : femaleMax
    return [0, Math.ceil(max / 10) * 10]
  }

  yAxisFormat = (y) => {
    return y <= 0? 0: y
  }
  onMouseLeave = () => {
    this.setState({ crosshairValues: [], })
  };
  onNearestX = (_, { index }) => {
    this.setState({ crosshairValues: [this.maleList[index], this.femaleList[index]], })
  }
  crosshairItemsFormat = () => {
    return [
      { title: this.props.t('male'), value: this.state.crosshairValues[0].y },
      { title: this.props.t('female'), value: this.state.crosshairValues[1].y }
    ]
  }
  render = () => {
    return <div>
      <Typography variant='h4' color='inherit' gutterBottom>
        {this.props.t('gender')}
      </Typography>
      <FlexibleXY 
        xType='ordinal' 
        height={300} 
        xDistance={100} 
        yDomain={this.yDomain}
        onMouseLeave={this.onMouseLeave}
      >
        <DiscreteColorLegend 
          style={{ 
            position: 'absolute', 
            left: '50px', 
            top: '10px' 
          }} 
          orientation='horizontal'
          items={this.colorLegendItems} 
        />
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis title={getTimeTitle(this.props.t, this.range)} />
        <YAxis title={this.props.t('amount')} tickFormat={this.yAxisFormat} />
        {getBarSeries(this.maleList, this.maleColor, { onNearestX: this.onNearestX })}
        {getBarSeries(this.femaleList, this.femaleColor)}
        <Crosshair
          values={this.state.crosshairValues}
          itemsFormat={this.crosshairItemsFormat}
          titleFormat={() => null}
          style={{
            line: {
              background: this.theme.palette.lineStroke[this.type],
            }
          }}
        />
      </FlexibleXY>
    </div>
  }
}

GenderChart.propTypes = {
  t: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(GenderChart)
